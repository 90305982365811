/**
 * = Modals
 */

.modal {
    width: 100%;
    // margin: auto;
    overflow: scroll;
    // align-items: center;
    // align-content: center;
    // align-self: center;
    // justify-content: center;
    // justify-items: center;
    // justify-self: center;
    // &.static-example {
    //     position: relative;
    //     display: block;
    // }
}

.modal-content {
    // border: 1px solid red;
    width: 900px;
    min-height: 900px;
    height: max-content;
    margin: auto;
    // direction: rtl;
    // align-items: center;
    // align-content: center;
    // align-self: center;
    // justify-content: center;
    // justify-items: center;
    // justify-self: center;
    // position: absolute;

    // margin-left: -40%;
    // overflow: auto;

    @if $enable-rounded {
        border-radius: $modal-content-border-radius;
    }

    @else {
        border-radius: 0;
    }
}

.modal-fluid {
    .modal-dialog {
        margin-top: 0;
        margin-bottom: 0;
    }

    .modal-content {
        border-radius: 0;
    }
}